import rock01 from '../assets/rocks/rock01.png';
import rock02 from '../assets/rocks/rock02.png';
import rock03 from '../assets/rocks/rock03.png';
import pickaxe from '../assets/pickaxe.png';
import glow from '../assets/glow.png';
import back_button from '../assets/back_button.png';

import cloud1 from '../assets/clouds/cloud1.png';
import cloud2 from '../assets/clouds/cloud2.png';
import cloud3 from '../assets/clouds/cloud3.png';
import cloud4 from '../assets/clouds/cloud4.png';
import cloud5 from '../assets/clouds/cloud5.png';
import cloud6 from '../assets/clouds/cloud6.png';
import cloud7 from '../assets/clouds/cloud7.png';
import fog from '../assets/clouds/fog.png';

export const SPRITE_SHEETS_ARRAY = [
  {
    name: 'rock01',
    image: rock01,
  },
  {
    name: 'rock02',
    image: rock02,
  },
  {
    name: 'rock03',
    image: rock03,
  },
  {
    name: 'pickaxe',
    image: pickaxe,
  },
  {
    name: 'cloud1',
    image: cloud1,
  },
  {
    name: 'cloud2',
    image: cloud2,
  },
  {
    name: 'cloud3',
    image: cloud3,
  },
  {
    name: 'cloud4',
    image: cloud4,
  },
  {
    name: 'cloud5',
    image: cloud5,
  },
  {
    name: 'cloud6',
    image: cloud6,
  },
  {
    name: 'cloud7',
    image: cloud7,
  },
  {
    name: 'glow',
    image: glow,
  },
  {
    name: 'fog',
    image: fog,
  },
  {
    name: 'back_button',
    image: back_button,
  },
];
