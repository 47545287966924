import Phaser from 'phaser';
import { MintingScene } from './scenes/MintingScene';
import { PreloadScene } from './scenes/PreloadScene';

const scenes = [PreloadScene, MintingScene];

export default {
  name: 'MintingComponent',
  props: {
    msg: String,
  },
  data: () => ({
    game: null,
    ids: '',
    account: ''
  }),
  beforeUnmount() {
    this.game.destroy();
  },
  mounted() {
    const { ids, account } = this.$route.params;
    this.ids = ids;
    this.account = account;
    // const history = useHistory();
    // history.listen((change) => {
    //   console.log(change);
    //   if (change.pathname === '/') {
    //     if (this.game.current) {
    //       this.game.current.destroy(true);
    //     }
    //   }
    // });
    scenes[0].prototype.nftIds = ids;
    scenes[1].prototype.ethAddress = account;
    if (this.game) {
      this.game.destroy(true);
    }
    if (!this.game) {
      const g = new Phaser.Game({
        type: Phaser.CANVAS,
        scene: scenes,
        scale: {
          mode: Phaser.Scale.FIT,
          autoCenter: Phaser.Scale.CENTER_BOTH,
          width: 1366,
          height: 768,
        },
        canvas: document.getElementById('my-phaser-game'),
        // scaleMode: Phaser.Scale.RESIZE,
        // backgroundColor: '#ffffff',
        fps: 60,
      });
      this.game = g;
    }
  },
};
