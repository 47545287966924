import gravestones from '../assets/gravestones.png';
import gravestones_json from '../assets/gravestones.json';

import background_button_toggle from '../assets/background_button/background_button_toggle.png';
import background_button_toggle_json from '../assets/background_button/background_button_toggle.json';

export const ATLAS_CONFIG = [
  {
    name: 'gravestones',
    image: gravestones,
    json: gravestones_json,
  },
  {
    name: 'background_button_toggle',
    image: background_button_toggle,
    json: background_button_toggle_json,
  },
];
