import Phaser from 'phaser';

/**
 * Class responsible for creating the cloud/fog effect.
 * @class
 */
export class CloudEffects {
  constructor(scene) {
    /**
     * @type { Phaser.Scene }
     */
    this.scene = scene;
  }

  /**
   * Creates the clouds particles.
   * @param { number } width Map Width in pixels.
   * @param { number } height Map Height in Pixels.
   * @param { number } originX Origin of the clouds.
   * @param { number } originY Origin of the clouds.
   */
  makeClouds(width, height, originX, originY) {
    // Sets the bounds of the particles so they do not leave the respective zone.
    const deathZone = new Phaser.Geom.Rectangle(originX, originY, width, height);
    const offscreen = new Phaser.Geom.Rectangle(-300, 0, 100, 1000);
    const oncreen = new Phaser.Geom.Rectangle(0, 0, 1366, 768);

    this.particles = this.scene.add.particles('cloud5', [
      {
        angle: { min: 0, max: 360 },
        emitZone: { source: offscreen },
        deathZone: { source: deathZone, type: 'onLeave' },
        frequency: 7500,
        speedX: 15,
        speedY: 0,
        lifespan: 300000,
        scale: 0.8,
        alpha: { start: 0.4, end: 0.6 },
        radial: true,
        rotation: 180,
      },
    ]);

    this.particles2 = this.scene.add.particles('cloud6', [
      {
        angle: { min: 0, max: 360 },
        emitZone: { source: offscreen },
        deathZone: { source: deathZone, type: 'onLeave' },
        frequency: 5500,
        speedX: 20,
        speedY: 0,
        lifespan: 300000,
        scale: 0.8,
        alpha: { start: 0.4, end: 0.6 },
        radial: true,
        rotation: 180,
      },
    ]);
    this.particles3 = this.scene.add.particles('cloud7', [
      {
        angle: { min: 0, max: 360 },
        emitZone: { source: offscreen },
        deathZone: { source: deathZone, type: 'onLeave' },
        frequency: 10500,
        speedX: 10,
        speedY: 0,
        lifespan: 300000,
        scale: 0.8,
        alpha: { start: 0.4, end: 0.6 },
        radial: true,
        rotation: 180,
      },
    ]);

    this.particles4 = this.scene.add.particles('cloud5', [
      {
        angle: { min: 0, max: 360 },
        emitZone: { source: oncreen },
        deathZone: { source: deathZone, type: 'onLeave' },
        frequency: 555500,
        speedX: 15,
        speedY: 0,
        lifespan: 300000,
        scale: 0.8,
        alpha: { start: 0.4, end: 0.6 },
        radial: true,
        rotation: 180,
      },
    ]);

    this.particles5 = this.scene.add.particles('cloud6', [
      {
        angle: { min: 0, max: 360 },
        emitZone: { source: oncreen },
        deathZone: { source: deathZone, type: 'onLeave' },
        frequency: 557500,
        speedX: 20,
        speedY: 0,
        lifespan: 300000,
        scale: 0.8,
        alpha: { start: 0.4, end: 0.6 },
        radial: true,
        rotation: 180,
      },
    ]);
    this.particles5 = this.scene.add.particles('cloud7', [
      {
        angle: { min: 0, max: 360 },
        emitZone: { source: oncreen },
        deathZone: { source: deathZone, type: 'onLeave' },
        frequency: 1550500,
        speedX: 10,
        speedY: 0,
        lifespan: 300000,
        scale: 0.8,
        alpha: { start: 0.4, end: 0.6 },
        radial: true,
        rotation: 180,
      },
    ]);

    // Clouds should alway cast shadows above everything else in the map.
    this.particles.depth = 10;
    this.particles2.depth = 10;
    this.particles3.depth = 10;
  }
}
