import pick_axe01 from '../assets/pick_axe01.mp3';
import pick_axe02 from '../assets/pick_axe02.mp3';
import pick_axe03 from '../assets/pick_axe03.mp3';
import background from '../assets/background_music.mp3';
import thunder from '../assets/thunder.mp3';

export const SOUND_EFFECTS_ASSETS = [
  {
    name: 'pick_axe01',
    file: pick_axe01,
  },
  {
    name: 'pick_axe02',
    file: pick_axe02,
  },
  {
    name: 'pick_axe03',
    file: pick_axe03,
  },
  {
    name: 'background',
    file: background,
  },
  {
    name: 'thunder',
    file: thunder,
  },
];
