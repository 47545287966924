import Phaser from 'phaser';
import { PlayerEntity } from '../../../phaser/entities/PlayerEntity';
import { CloudEffects } from '../effects/CloudEffects';
import { SoundEffects } from '../effects/SoundEffects';
import { StoneParticles } from '../effects/StoneParticle';
import { GameUtils } from '../Utils/GameUtils';

export class MintingScene extends Phaser.Scene {
  constructor() {
    super({
      key: 'MintingScene',
    });
    /**
     * The number of times that the user has to click on the stone to make it change it's state.
     */
    this.breakingSplit = 2;

    /**
     * The nubmer of times that the user clicked the stone.
     */
    this.breakingCounter = 0;

    /**
     * Maximum number of steps to break the ghost out.
     */
    this.gravestoneMaxSteps = 6;
    /**
     * The current step that the gravestone is in.
     */
    this.gravestoneCurrentStep = 0;

    /**
     * The Minted Ghosts.
     */
    this.ghosts = [];

    /**
     * The floating tweens of the ghosts.
     */
    this.ghostTweens = [];

    /**
     * The payload from the API with the data from the minted ghosts.
     */
    this.apiPayload = [];
  }

  preload() {
    if (this.apiPayload.length > 0)
      this.apiPayload.forEach((ghost) => {
        this.load.image(`ghost${ghost.id}`, ghost.image);
      });
    this.apiPayload.forEach((ghost) => {
      ghost.attributes.forEach((attribute) => {
        this.load.image(
          `${attribute.trait_type}_${attribute.value}`,
          `${process.env.VUE_APP_SERVER_URI}/traits/${attribute.trait_type}/${attribute.value}.png`
        );
      });
    });
    this.loadingText = this.add
      .text(this.cameras.main.centerX, this.cameras.main.centerY, 'Loading...', {
        fontSize: '62px',
        color: '#ffffff',
        fontFamily: 'BlackBeard',
      })
      .setOrigin(0.5);
  }

  init(payload) {
    this.apiPayload = payload;
  }
  create() {
    this.gravestone = this.add
      .sprite(0, 0, 'gravestones')
      .setOrigin(0, 0)
      .setInteractive();
    this.gravestone.setDisplaySize(this.game.config.width, this.game.config.height);

    this.smallFog = this.add
      .tileSprite(0, 0, this.game.config.width, this.game.config.height, 'fog')
      .setOrigin(0, 0)
      .setDisplaySize(this.game.config.width, this.game.config.height)
      .setScrollFactor(0);

    this.clickZone = this.add
      .zone(450, 120, 470, 580)
      .setInteractive()
      .setOrigin(0, 0);
    // const debugZone = this.add.graphics();
    // debugZone.strokeRect(this.clickZone.x, this.clickZone.y, this.clickZone.width, this.clickZone.height);

    this.pickaxe = this.add.sprite(0, 0, 'pickaxe').setOrigin(0.5, 0.5);
    this.pickaxe.depth = 7;
    // console.log(this.pickaxe.width * this.pickaxe.scaleX, this.pickaxe.height * this.pickaxe.scaleY);
    this.cloudEffects = new CloudEffects(this);
    this.cloudEffects.makeClouds(3000, 3000, -500, -500);
    this.stoneParticles = new StoneParticles(this);
    this.soundEffects = new SoundEffects(this);

    this.gravestoneText = this.add
      .text(690, 330, this.ethAddress, {
        fontSize: '40px',
        color: '#000000',
        fontStyle: 'bold',
        fontFamily: 'BlackBeard',
        align: 'center',
        wordWrap: { width: 320, useAdvancedWrap: true },
        maxLines: 3,
      })
      .setOrigin(0.5, 0.5);
    this.gravestoneText.depth = 1;

    const ghostDestinationY = this.gravestone.y + (this.gravestone.height * this.gravestone.scaleY) / 1.2;

    const padding = 460;
    const originOffset = 230;
    let xOriginPosition = this.gravestone.x + (this.gravestone.width * this.gravestone.scaleY) / 2;
    if (this.apiPayload.length == 2) xOriginPosition = xOriginPosition - originOffset;
    if (this.apiPayload.length == 3) xOriginPosition = xOriginPosition - originOffset * 2;
    this.apiPayload.forEach((parameters, index) => {
      const oniConfig = {};
      const parts = parameters.attributes.map((attribute) => ({ [attribute.trait_type]: attribute.value }));
      parts.forEach((part) => {
        Object.keys(part).forEach((key) => {
          oniConfig[key] = part[key];
        });
      });
      const ghost = new PlayerEntity(this, xOriginPosition + padding * index, ghostDestinationY, oniConfig);
      ghost.setOrigin(0.5, 1);
      ghost.setScale(0.45);
      ghost.alpha = 0;
      ghost.entityBodyParts.Background.alpha = 0;
      ghost.depth = 20;

      this.ghosts.push(ghost);
      setTimeout(() => {
        const glowImage = this.add
          .image(ghost.x, ghost.y, 'glow')
          .setOrigin(0.5, 1)
          .setScale(0.9);
        const glow = this.tweens.add({
          targets: glowImage,
          scaleX: 0.95,
          duration: 1000,
          ease: 'Sine.easeInOut',
          yoyo: true,
          repeat: -1,
        });
        glowImage.depth = 19;
        const floatingAnimation = this.tweens.add({
          targets: [ghost, glowImage],
          y: ghost.y - 20,
          duration: 1600,
          yoyo: true,
          ease: 'Sine.easeInOut',
          repeat: -1,
        });

        glowImage.alpha = 0;
        this.ghostTweens.push({ floatingAnimation, glow, glowImage });
      }, 500 * index);
    });
    this.backgroundAudio = this.sound.add('background', {
      loop: true,
    });
    this.backgroundAudio.play();
    const backButton = this.add
      .image(30, 20, 'back_button')
      .setOrigin(0, 0)
      .setScale(0.25)
      .setInteractive();
    backButton.on('pointerdown', (pointer) => {
      window.location.href = '/';
    });
    const btnToggleBackground = this.add
      .sprite(this.game.config.width - 120, 40, 'background_button_toggle')
      .setOrigin(0.5, 0.5);
    btnToggleBackground.setInteractive();
    btnToggleBackground.play('btn_off');
    btnToggleBackground.setScale(0.25);
    btnToggleBackground.alpha = 0;
    btnToggleBackground.on('poitermove', () => {
      this.game.canvas.style.cursor = 'pointer';
    });
    btnToggleBackground.on('pointerdown', () => {
      this.ghosts.forEach((ghost) => {
        ghost.entityBodyParts.Background.alpha = ghost.entityBodyParts.Background.alpha == 1 ? 0 : 1;
        if (ghost.entityBodyParts.Background.alpha) {
          btnToggleBackground.play('btn_on');
          this.ghostTweens.forEach((tween) => {
            tween.floatingAnimation.pause();
            tween.glow.pause();
            tween.glowImage.alpha = 0;
          });
        } else {
          this.ghostTweens.forEach((tween) => {
            tween.floatingAnimation.resume();
            tween.glow.resume();
            tween.glowImage.alpha = 1;
          });
          btnToggleBackground.play('btn_off');
        }
      });
    });

    this.input.on('pointermove', (pointer) => {
      this.pickaxe.x = pointer.x;
      this.pickaxe.y = pointer.y;
    });
    this.clickZone.on('pointermove', () => {
      if (
        !this.pickaxeRotation ||
        (!this.pickaxeRotation.isPlaying() && this.gravestoneCurrentStep !== this.gravestoneMaxSteps)
      ) {
        this.pickaxeRotation = this.add.tween({
          targets: this.pickaxe,
          angle: { from: 0, to: 65 },
          yoyo: true,
          duration: 500,
        });
      }
    });
    this.clickZone.on('pointerdown', (pointer) => {
      if (this.gravestoneCurrentStep !== this.gravestoneMaxSteps) {
        this.breakingCounter++;
        this.soundEffects.playPickAxeAudio();
        this.add.tween({
          targets: this.pickaxe,
          duration: 90,
          angle: { from: 0, to: 65 },
          yoyo: true,
          repeat: 0,
          onComplete: () => {
            const x = pointer.x + (this.pickaxe.width * this.pickaxe.scaleX) / 1.9;
            const y = pointer.y - ((this.pickaxe.height * this.pickaxe.scaleY) / 2) * 0.05;
            this.stoneParticles.explode(x, y);
            this.cameras.main.shake(100, 0.005);
          },
        });
        if (this.breakingSplit === this.breakingCounter) {
          this.breakingCounter = 0;
          if (this.gravestoneCurrentStep < this.gravestoneMaxSteps) this.gravestoneCurrentStep++;
        }
        this.gravestone.play(
          `gravestones/stage${GameUtils.zeroPad(this.gravestoneCurrentStep, 2)}/grave${this.gravestoneCurrentStep}`
        );

        // When it's finished.
        if (this.gravestoneCurrentStep === this.gravestoneMaxSteps) {
          this.gravestoneText.rotation = 0.77;
          this.gravestoneText.setPosition(1020, 470);
          this.sound.play('thunder');
          this.cameras.main.flash(1000);
          this.add.tween({
            targets: [...this.ghosts, btnToggleBackground, ...this.ghostTweens.map((tween) => tween.glowImage)],
            // scale: { from: 0, to: 0.7 },
            alpha: { from: 0, to: 1 },
            duration: 2500,
          });
          this.add.tween({
            targets: [...this.ghostTweens.map((tween) => tween.glowImage)],
            // scale: { from: 0, to: 0.7 },
            alpha: { from: 0, to: 0.5 },
            duration: 2500,
          });
          setTimeout(() => {
            this.cameras.main.flash(1000);
            this.sound.play('thunder');
          }, 1500);
        }
      }
    });
  }
  update() {
    this.smallFog.tilePositionX = this.smallFog.tilePositionX - 0.3;
  }
}
