/**
 * This class is responsible for creating the stone particles that drop from the Tomb Stone every click.
 * @class
 */
export class StoneParticles {
  constructor(scene) {
    this.scene = scene;
    this.generate();
  }
  generate() {
    this.emitter0 = this.scene.add.particles('rock01').createEmitter({
      x: 400,
      y: 300,
      speed: { min: -800, max: 800 },
      angle: { min: 0, max: 360 },
      rotate: { min: 0, max: 360 },
      scale: { start: 0.5, end: 0 },
      // active: false,
      lifespan: 600,
      gravityY: 1200,
    });

    this.emitter1 = this.scene.add.particles('rock02').createEmitter({
      x: 400,
      y: 300,
      speed: { min: -800, max: 800 },
      angle: { min: 0, max: 360 },
      rotate: { min: 0, max: 360 },
      scale: { start: 0.3, end: 0 },
      // active: false,
      lifespan: 300,
      gravityY: 1200,
    });

    this.emitter2 = this.scene.add.particles('rock03').createEmitter({
      x: 400,
      y: 300,
      speed: { min: -800, max: 800 },
      angle: { min: 0, max: 360 },
      rotate: { min: 0, max: 360 },
      scale: { start: 0.8, end: 0 },
      // active: false,
      lifespan: 300,
      gravityY: 1200,
    });
    this.emitter0.visible = false;
    this.emitter1.visible = false;
    this.emitter2.visible = false;
    this.emitter0.depth = 80;
    this.emitter1.depth = 80;
    this.emitter2.depth = 80;
    this.emitter0.explode();
    this.emitter1.explode();
    this.emitter2.explode();
  }

  explode(x, y) {
    this.emitter0.visible = true;
    this.emitter1.visible = true;
    this.emitter2.visible = true;
    this.emitter0.setPosition(x, y);
    this.emitter1.setPosition(x, y);
    this.emitter2.setPosition(x, y);
    Array(5)
      .fill(null)
      .forEach(() => {
        this.emitter0.explode();
        this.emitter1.explode();
        this.emitter2.explode();
      });
  }
}
