/**
 * This class is responsible for managing the sound effects of the game.
 * @class
 */
export class SoundEffects {
  constructor(scene) {
    /**
     * @type { Phaser.Scene }
     */
    this.scene = scene;

    this.pickAxeAudios = ['pick_axe01', 'pick_axe02', 'pick_axe03'];
  }

  playPickAxeAudio() {
    const audio = this.pickAxeAudios[Math.floor(Math.random() * this.pickAxeAudios.length)];
    this.scene.sound.play(audio);
  }
}
